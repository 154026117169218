import { ChangeEvent, useEffect, useState } from "react";
import { useParams } from "react-router";
import { ParticipantType } from "../types/participant";
import { request } from "../api/config";
import moment from "moment";
import { Button, FileInput, Label, Spinner } from "flowbite-react";
import { X } from "lucide-react";

const Confirmation = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [participant, setParticipant] = useState<ParticipantType | undefined>(
    undefined
  );
  const [ktp, setKTP] = useState<File | undefined>(undefined);
  const [selfie, setSelfie] = useState<File | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [errorRes, setErrorRes] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState<boolean>(false);

  const { token } = useParams();

  const getToken = async () => {
    try {
      const { data } = await request.get(`/confirmation/${token}`);
      return data;
    } catch (err: any) {
      setError(err.response.data.message);
    }
  };

  useEffect(() => {
    Promise.all([getToken()]).then((res) => {
      setParticipant(res[0]?.data);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const styles: React.CSSProperties = {
    background:
      "linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.4)), url(/bgconfirmation.jpg)",
    backgroundSize: "cover",
    position: "relative",
  };

  const handleFileKTP = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      // Assuming you want to handle only the first selected file
      const file = files[0];
      setKTP(file);
    }
  };

  const handleFileSelfie = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      // Assuming you want to handle only the first selected file
      const file = files[0];
      setSelfie(file);
    }
  };

  const handleUpload = async () => {
    setLoadingSubmit(true);

    // Check if both KTP and Selfie are uploaded
    if (!ktp || !selfie) {
      alert("Oops, anda harus melampirkan foto KTP dan Selfie");
      setLoadingSubmit(false);
      return;
    }

    try {
      const payload = new FormData(); // Use FormData for multipart data
      payload.append("ktp", ktp);
      payload.append("selfie", selfie);

      await request
        .post(`/confirmation/${token}/confirm`, payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          setKTP(undefined);
          setSelfie(undefined);
          setSuccess(true);
        });
    } catch (err: any) {
      // Check if the error has a response and the status is 422
      if (err.response && err.response.status === 422) {
        const errorMessage = err.response.data?.message || "Validation error!";
        setErrorRes(errorMessage); // Use the error message from the response
      } else {
        setErrorRes("Oops, ada terjadi kesalahan server!");
      }
    } finally {
      setLoadingSubmit(false);
    }
  };

  return (
    <>
      {errorRes && (
        <div className="fixed top-5 right-5 z-10 bg-red-50 text-red-700 rounded-lg py-2 px-4">
          <div className="flex items-center gap-4">
            <span>{errorRes}</span>{" "}
            <X
              className="cursor-pointer"
              onClick={() => setErrorRes(undefined)}
            />
          </div>
        </div>
      )}
      <>
        <div
          className="w-full flex items-center justify-center h-screen p-6"
          style={styles}
        >
          <div className="w-full max-w-xl bg-white rounded-xl p-6 shadow-lg">
            {error && (
              <>
                <h1 className="text-4xl font-bold">Oops!</h1>
                <div className="mt-3">
                  <p className="text-xl">{error}</p>
                </div>
              </>
            )}

            {success && (
              <>
                <h1 className="text-4xl font-bold">🎉 Yeey!</h1>
                <div className="mt-3">
                  <p className="text-xl">
                    Konfirmasi anda sudah kami simpan. Terima kasih 😊
                  </p>
                </div>
              </>
            )}

            {!error && !success && (
              <>
                {loading ? (
                  <></>
                ) : (
                  <>
                    <h1 className="text-2xl font-bold">
                      Hai, {participant?.participant.name}
                    </h1>
                    <div className="mt-2">
                      <p className="text-lg">
                        Anda diundang untuk mengikuti tes asesmen pada{" "}
                        {participant?.participant.test_date
                          ? moment(participant?.participant.test_date).format(
                              "DD MMM YYYY"
                            )
                          : "-"}
                        .
                      </p>
                      <p className="text-lg">
                        Untuk mengkonfirmasi kehadiran anda silahkan upload foto
                        KTP dan selfie anda
                      </p>
                    </div>
                    <div className="mt-3 pt-3 border-t">
                      <div id="ktp" className="">
                        <div className="mb-2 block">
                          <Label htmlFor="ktp" value="Foto KTP" />
                        </div>
                        <FileInput id="file" onChange={handleFileKTP} />
                      </div>

                      <div id="selfie" className="mt-2">
                        <div className="mb-2 block">
                          <Label htmlFor="selfie" value="Foto Selfie" />
                        </div>
                        <FileInput id="selfie" onChange={handleFileSelfie} />
                      </div>

                      <div className="w-full mt-4">
                        <Button
                          className="w-full"
                          color="dark"
                          onClick={handleUpload}
                        >
                          {loadingSubmit ? (
                            <Spinner />
                          ) : (
                            <p className="font-bold text-base">Konfirmasi</p>
                          )}
                        </Button>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </>
    </>
  );
};

export default Confirmation;
